/* eslint-disable import/prefer-default-export */
export const ONBOARD_SPEND_UPCODES = {
  'Princess Packages Onboard': 'UP-13566758-53',
  'Effy Onboard Carnival': 'UP-79934337-51',
  'Effy Onboard Princess': 'UP-79934337-54',
  'Effy Onboard Holland': 'UP-79934337-52',
  'Effy In-Port': 'UP-79934337-53',
  'Effy VIP': 'UP-79934337-50',
  'Effy VIP VCC': 'UP-79934337-55',
  'Starboard Onboard Carnival': 'UP-11380191-50',
  'Harding Retail Onboard Carnival': 'UP-88126342-50',
  'EFFY Onboard NCL': 'UP-79934337-56',
  'EFFY Onboard Celebrity': 'UP-79934337-57',
};
