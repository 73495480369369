import sha512 from 'crypto-js/sha512';

import { ACTIONS } from 'state/actionTypes';
import { httpError } from 'lib/utils';

// prettier-ignore
export const accountsReducer = (state = {}, action) => { // NOSONAR
  switch (action.type) {
    case ACTIONS.getAccount:
      return {
        loading: true,
      };
    case ACTIONS.getAccountSuccess:
      if ('dismissAutoPayBanner' in sessionStorage && action.payload.data.autoPay) {
        sessionStorage.removeItem('dismissAutoPayBanner');
      }
      localStorage.setItem('account_id_hash', sha512(action.payload.data.accountId).toString());
      return {
        data: action.payload.data,
      };
    case ACTIONS.getAccountFail:
      if (action.error?.response?.data?.migrationStatus
        && action.error?.response?.status === 503){
          return {
            loading: false,
            data: {
              migrationStatus: action.error.response.data?.migrationStatus,
              migrationData: action.error.response.data?.migrationData
            }
        }
      }
      return {
        ...state,
        loading: false,
        error: httpError(action),
      };
    case ACTIONS.putAutoPayPersistSuccess:
    case ACTIONS.paymentMethodPersistAutoPay:
      return {
        ...state,
        data: { ...state.data, autoPay: true },
      };
    case ACTIONS.updatePrimaryContact: 
      return {
        ...state,
        data: {
          ...state.data,
          primaryContact: {
            ...state.data?.primaryContact,
            ...action?.payload?.primaryContact
          },
        },
      };
    case ACTIONS.resetAccount:
      return {};
    case ACTIONS.setMigrationAccount:
      return {
        data: {
          migrationStatus: action.payload?.migrationStatus,
          migrationData: action.payload?.migrationData,
        }
      }
    default:
      return state;
  }
};

export default accountsReducer;
