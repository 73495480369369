const Theme = {
  colors: {
    upliftTeal: '#2ea59a',
    upliftTealActive: '#1c8f84',
    upliftLightTeal: '#dff3f1',
    upliftLightestTeal: '#eff9f8',
    upliftTealBorder: '#8fdfcf',
    upliftTealText: '#217169',
    upliftBlue: '#0c60f0',
    upliftNavy: '#003366',
    upliftOrange: '#fb6801',
    upliftPurple: '#ad01fb',
    upliftLightBlack: '#555555',
    upliftRealLightBlack: '#222222',
    upliftLightGrey: '#bdc5d7',
    upliftWhite: '#fff',
    upgradeGreen: '#0E680E',
    upgradeActiveButton: '#0a3f07',
    upgradeGrey: '#515151',
    backgroundPendingRefund: '#85aff7',
    backgroundTeal: '#e3f8ee',
    backgroundBlue: '#4d71ff',
    backgroundLightBlue: '#eff4fa',
    backgroundGrey: '#dde1ea',
    backgroundGrey2: '#b2b7bf',
    backgroundError: '#FFF1ED',
    backgroundAdmin: '#ffeaa7',
    backgroundAdminActive: 'yellow',
    borderLightGrey: '#ededed',
    borderGrey: '#d1d3d7',
    borderBlack: '#000816',
    borderBrown: '#95761E',
    boxShadowLightOpacity: '#00000040',
    infoAccent: '#0C65C3',
    infoBackground: '#EDEFF9',
    errorMessage: '#ff0000',
    warningYellow: '#fff6d1',
    successGreen: '#17AD4E',
    headerGrey: '#586272',
    hyperlinkBlue: '#156CED',
    hyperlinkBlueHover: '#0056b3',
    black: '#000000',
    textBlack: '#484f5c',
    secondaryMediumBlue: '#2462BC',
    error: '#EE0606',
    grey: '#383D47',
    indeterminate: '#B2B7BF',
    warning: '#FFD119',
    white: '#ffffff',
    iconGrey: '#252930',
    labelGrey: '#8C95A6',
    hardshipStatusOrange: '#F17D58',
    warningBrown: '#7B631C',
    warningIcon: '#CFA520',
    dangerRed: '#733134',
    contentGrey: '#3A3B41',
    borderBottomLine: '#CFD1D8',
    contentGreySecondary: '#373B44',
  },
  font: {
    fontFamily: {
      default: 'Rubik, "Open Sans", sans-serif, Helvetica, Arial',
      flexpay: 'Graphik',
    },
  },
  minWidth: {
    atto: '321px',
    femto: '404px',
    pico: '481px',
    nano: '641px',
    micro: '768px',
    milli: '796px',
    kilo: '961px',
    mega: '992px',
    giga: '1025px',
    tera: '1281px',
  },
};

export default Theme;
