import React, { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { PropTypes } from 'prop-types';
import {
  MigratedStyledText,
  NextStepsContainer,
  NextStepsIndex,
  StyledListItem,
  Column,
  StyledListText,
  SpecialDescription,
  StyledItalicizedText,
  StyledOutboundLink,
} from 'styles/CommonStyles';
import { formatPhoneNumber } from 'lib/utils';
import { MIGRATED_LOGIN_CASE } from 'lib/constants/migrationStatuses';
import InfoDiagram from './InfoDiagram';
import FAQ from './FAQ';

const PendingMergeUserView = memo(({ migrationData }) => {
  const { t } = useTranslation();
  const steps = t('account.pending_merge_user.set_up_steps', { returnObjects: true });
  const { mobile, upgradeMobile } = migrationData;
  const differentNumbers = mobile !== upgradeMobile;
  const formattedMobile = differentNumbers ? formatPhoneNumber(upgradeMobile, false) : formatPhoneNumber(upgradeMobile, true);
  const upgradeDashboardUrl = 'https://www.upgrade.com/auth/flexpay/enroll?method=phone';

  return (
    <>
      <NextStepsContainer>
        <MigratedStyledText>
          {' '}
          <Trans i18nKey="account.pending_merge_user.manage_services" />
        </MigratedStyledText>
        <MigratedStyledText fontWeight={500}>{t('account.pending_merge_user.next_steps')}</MigratedStyledText>
        {Object.entries(steps).map((step, index) => (
          <StyledListItem>
            <NextStepsIndex>{index + 1}</NextStepsIndex>
            <Column style={{ width: '100%' }}>
              <StyledListText>{step[1]}</StyledListText>
              {index === 0 && (
                <SpecialDescription>
                  <div>
                    {differentNumbers ? (
                      <Trans i18nKey="account.pending_merge_user.step_one_multiple_numbers">
                        We found more than one phone number associated with your account.
                        <MigratedStyledText fontWeight={600}>Please continue with {{ formattedMobile }}</MigratedStyledText>.
                      </Trans>
                    ) : (
                      <Trans i18nKey="account.pending_merge_user.step_one_verify">
                        To verify, use these last four digits of the phone number associated with your Uplift account:
                        <MigratedStyledText fontWeight={600}>{{ formattedMobile }}</MigratedStyledText>
                      </Trans>
                    )}
                  </div>
                </SpecialDescription>
              )}
            </Column>
          </StyledListItem>
        ))}
        <StyledOutboundLink href={upgradeDashboardUrl} target="_blank" rel="noopener noreferrer">
          {t('account.pending_merge_user.merge_account')}
        </StyledOutboundLink>
        <StyledItalicizedText>
          {differentNumbers ? (
            <Trans i18nKey="account.pending_merge_user.if_you_dont_have_access">
              If you don&apos;t have access to {{ formattedMobile }} then please call (844) 257-5400 to update your information
            </Trans>
          ) : (
            t('account.pending_merge_user.invalid_phone_number')
          )}
        </StyledItalicizedText>
      </NextStepsContainer>
      <InfoDiagram loginCase={MIGRATED_LOGIN_CASE.PENDING_USER} />
      <FAQ loginCase={MIGRATED_LOGIN_CASE.PENDING_USER} />
      <StyledOutboundLink href={upgradeDashboardUrl} target="_blank" rel="noopener noreferrer">
        {t('account.pending_merge_user.merge_account')}
      </StyledOutboundLink>
    </>
  );
});

PendingMergeUserView.displayName = 'PendingMergeUserView';

PendingMergeUserView.propTypes = {
  migrationData: PropTypes.shape({
    loginCase: PropTypes.oneOf(Object.keys(MIGRATED_LOGIN_CASE)).isRequired,
    emailAddress: PropTypes.string,
    mobile: PropTypes.string,
    upgradeEmailAddress: PropTypes.string,
    upgradeMobile: PropTypes.string,
  }).isRequired,
};

export default PendingMergeUserView;
