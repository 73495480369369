import React, { useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import trackEvent from 'analytics/trackEvent';
import OutboundLink from 'analytics/components/OutboundLink';
import * as cevents from 'up.lib.js.cevents';

import { toKebabCase } from 'lib/utils';

import { accountIdSelector } from 'state/account/selectors';

import { useStorage } from 'hooks/useStorage';

import { MaterialIcon } from 'styles/Icons';

import { Button } from 'styles/CommonStyles';

const StyledBanner = styled.div`
  background-color: ${(props) => props.theme.colors.upliftTeal};
  color: ${(props) => props.theme.colors.upliftWhite};
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  min-height: 50px;
  padding: 1rem;
`;

const StyledMessage = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`;

const Message = styled.div`
  font-size: 0.85rem;
  text-align: center;

  padding: 0 2rem;
`;

const CloseButton = styled.button`
  background-color: ${(props) => props.theme.colors.upliftTeal};
  color: ${(props) => props.theme.colors.upliftWhite};

  outline: none;
  border: none;
  cursor: pointer;

  padding-top: 5px;

  margin-left: auto;
`;

const HiddenButton = styled.button`
  background-color: ${(props) => props.theme.colors.upliftOrange};
  color: ${(props) => props.theme.colors.upliftWhite};

  outline: none;
  border: none;
  cursor: pointer;

  padding-top: 5px;

  margin-right: auto;
  visibility: hidden;
`;

const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.black};
  font-size: 0.85rem;
  min-width: 62px;

  text-transform: none;

  & a {
    color: ${(props) => props.theme.colors.upliftWhite};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.upliftRealLightBlack};
    color: #fff !important;
  }

  &:hover > a {
    background-color: ${(props) => props.theme.colors.upliftRealLightBlack};
    color: #fff !important;
  }
`;

const BigUpliftBanner = memo(() => {
  const { t } = useTranslation();
  const accountId = useSelector(accountIdSelector);
  const [display, setDisplay] = useStorage(sessionStorage, 'dismissBigUpliftBanner');

  const ctaUrl =
    'http://www.uplift.com/black-friday-travel-deals?utm_source=bp&utm_medium=banner&utm_campaign=biguplift&utm_id=uplift';

  const onClose = useCallback(
    (event) => {
      event.preventDefault();

      // google analytics
      const gaEvent = {
        category: 'user',
        action: 'click',
        label: 'close-big-uplift-campaign-banner',
        value: 1,
      };
      trackEvent(gaEvent);

      // cevents
      let metadata = {};
      metadata.uplift_account_id = localStorage.getItem('account_id_hash');
      metadata.isAdmin = localStorage.getItem('adminAuth');
      metadata = toKebabCase(metadata);

      cevents.setLevel('debug');
      cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);
      if (accountId) {
        setDisplay(accountId);
      }
    },
    [accountId, setDisplay]
  );

  return display !== accountId ? (
    <StyledBanner>
      <HiddenButton>
        <MaterialIcon name="close" />
      </HiddenButton>
      <StyledMessage>
        <Message data-testid="big-uplift-campaign-message">{t('banner.big_uplift.message')}</Message>
        <StyledButton>
          <OutboundLink eventLabel="big-uplift-campaign-info" to={ctaUrl} target="_blank" rel="noopener" isbutton="true">
            {t('banner.big_uplift.cta')}
          </OutboundLink>
        </StyledButton>
      </StyledMessage>
      <CloseButton onClick={onClose}>
        <MaterialIcon name="close" />
      </CloseButton>
    </StyledBanner>
  ) : null;
});

BigUpliftBanner.displayName = 'BigUpliftBanner';

export default BigUpliftBanner;
