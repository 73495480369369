import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import Loader from 'components/Loader';

import { useStorage } from 'hooks/useStorage';

import { scrollToElement } from 'lib/utils';

import { accountIdSelector, countrySelector } from 'state/account/selectors';
import {
  addLoanAutoPayDocument,
  enableLoanAutoPay,
  setAutoPayModalState,
  setShowAutoPayFail,
  setShowAutoPaySuccess,
} from 'state/autoPay/actions';
import { autoPayModalLoanSelector, autoPayInitializeSelector, setAutoPayModalStateSelector } from 'state/autoPay/selectors';
import { switchPaymentMethodForLoan } from 'state/loans/setPaymentMethod/actions';
import { loansSelector } from 'state/loans/selectors';
import { paymentMethodsSelector } from 'state/paymentMethods/selectors';

import AchEncouragementModal from 'views/loans/components/AchEncouragementModal';
import AutoPayFlow from 'views/account/wallet/components/AutoPayFlow';
import { Panel } from 'styles/CommonStyles';
import LoansDetailsView from './LoansDetailsView';

const LoansDetails = (loans) => {
  return loans
    .sort((a, b) => (a.loanDate < b.loanDate ? 1 : -1))
    .map((loan) => <LoansDetailsView key={loan.loanId} loanDetails={loan} />);
};

const LoansDetailsContainer = memo(() => {
  const dispatch = useDispatch();
  const { hash: locationHash } = useLocation();

  const accountId = useSelector(accountIdSelector);
  const country = useSelector(countrySelector);
  const autoPayInitializeData = useSelector(autoPayInitializeSelector);
  const autoPayLoan = useSelector(autoPayModalLoanSelector);
  const isShowAutoPay = useSelector(setAutoPayModalStateSelector);
  const loansDetails = useSelector(loansSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);
  const [loansWithoutAch, setLoansWithoutAch] = useState([]);
  const [showEncouragement, setShowEncouragement] = useState(false);
  const [storedId, setStoredId] = useStorage(localStorage, 'dismissACHModal');

  useEffect(() => {
    const loans =
      loansDetails &&
      loansDetails.filter((loan) => {
        const { paymentToken } = loan;
        if (!paymentToken) {
          return true;
        }
        const paymentMethod = paymentMethods?.find((method) => method.paymentMethodId === paymentToken);
        return !paymentMethod || paymentMethod.paymentMethodType !== 'ach';
      });

    setLoansWithoutAch(loans);
    setShowEncouragement(!!loans?.length);
  }, [loansDetails, paymentMethods]);

  useEffect(() => {
    if (locationHash) {
      const hashLoanId = locationHash.slice(1);
      if (hashLoanId) {
        const scrollTarget = document.getElementById(hashLoanId);
        if (scrollTarget) {
          scrollToElement(scrollTarget);
        }
      }
    }
  }, [locationHash]);

  const hideEncouragementModal = useCallback(() => {
    localStorage.setItem('dismissACHModal', accountId);
    if (accountId) {
      setStoredId(accountId);
      setShowEncouragement(false);
    }
  }, [accountId, setStoredId]);

  const { loanId, paymentToken } = autoPayLoan || {};

  const closeAutoPayFlow = useCallback(() => {
    dispatch(setAutoPayModalState(false));
  }, [dispatch]);

  const showAutoPayFail = useCallback(() => {
    dispatch(setShowAutoPayFail(true, loanId));
    dispatch(setAutoPayModalState(false));
  }, [dispatch, loanId]);

  const showAutoPaySuccess = useCallback(() => {
    dispatch(setShowAutoPaySuccess(true, loanId));
    dispatch(setAutoPayModalState(false));

    const { message, sessionId, ...newDocument } = autoPayInitializeData;
    dispatch(addLoanAutoPayDocument(loanId, newDocument));
    dispatch(switchPaymentMethodForLoan(loanId, paymentToken));
    dispatch(enableLoanAutoPay(loanId));
  }, [autoPayInitializeData, dispatch, loanId, paymentToken]);

  if (!loansDetails) {
    return (
      <Panel id="Panel">
        <Loader />
      </Panel>
    );
  }
  if (!loansDetails.length) {
    return (
      <Redirect
        to={{
          pathname: '/applications',
        }}
      />
    );
  }
  // activeLoans should appear before closedLoans
  const closedLoans = loansDetails.filter((loan) => loan.state?.toUpperCase() === 'CLOSED');
  const activeLoans = loansDetails.filter((loan) => loan.state?.toUpperCase() === 'ACTIVE');

  const missingAch = !paymentMethods?.find((method) => method.paymentMethodType === 'ach');

  return (
    <>
      {showEncouragement && storedId !== accountId && country !== 'MX' && (
        <AchEncouragementModal onClose={hideEncouragementModal} isMissingAch={missingAch} loans={loansWithoutAch} />
      )}
      {isShowAutoPay && (
        <AutoPayFlow
          loanId={loanId}
          merchantInfo={autoPayLoan?.merchantInfo}
          onAutoPayClose={closeAutoPayFlow}
          onAutoPayAuthorizeClose={closeAutoPayFlow}
          onAutoPayAuthorizeConfirm={showAutoPaySuccess}
          onError={showAutoPayFail}
          paymentMethodId={paymentToken}
        />
      )}
      {LoansDetails(activeLoans)}
      {LoansDetails(closedLoans)}
    </>
  );
});

export default LoansDetailsContainer;
