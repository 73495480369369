import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from 'components/GlobalContainer';
import upliftToFlexPayLogo from 'lib/assets/images/upliftToFlexPayLogo.svg';
import { Panel } from 'styles/CommonStyles';
import { MIGRATED_LOGIN_CASE } from 'lib/constants/migrationStatuses';
import 'lib/assets/css/styles-fonts.css';
import { useSelector } from 'react-redux';
import { accountStateSelector } from 'state/account/selectors';
import Loader from 'components/Loader';
import ExistingUpgradeUser from './components/ExistingUpgradeUserView';
import NewUpgradeUser from './components/NewUpgradeUserView';
import PendingMergeUserView from './components/PendingMergeUserView';

const StyledPanel = styled(Panel)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 40px;
  gap: 24px;
`;

const StyledFlexpayLogo = styled.img`
  width: 375px;
  align-self: center;
`;

const StyledHeaderText = styled.span`
  color: ${(props) => props.theme.colors.black};
  text-align: center;
  font-family: ${(props) => props.theme.font.fontFamily.flexpay};
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  align-self: stretch;
  line-height: normal;
`;

const getHeaderText = (loginCase) => {
  switch (loginCase) {
    case 'NEW_UPGRADE_USER':
      return 'account.migration.get_started';
    default:
      return 'account.migration.uplift_is_now_flex_pay';
  }
};

const Migrated = memo(({ migrationData }) => {
  const { t } = useTranslation();
  const accountState = useSelector(accountStateSelector);
  const PENDING_LOGIN_CASES = [MIGRATED_LOGIN_CASE.PENDING_USER, MIGRATED_LOGIN_CASE.PII_DISCREPANCIES];

  if (accountState?.loading) {
    return (
      <Container isMigrated>
        <Loader />
      </Container>
    );
  }
  return (
    <Container isMigrated>
      <StyledFlexpayLogo src={upliftToFlexPayLogo} />
      <StyledPanel isMigrated>
        <StyledHeaderText>{t(getHeaderText(migrationData?.loginCase))}</StyledHeaderText>
        {migrationData?.loginCase === MIGRATED_LOGIN_CASE.EXISTING_UPGRADE_USER && (
          <ExistingUpgradeUser migrationData={migrationData} />
        )}
        {migrationData.loginCase === MIGRATED_LOGIN_CASE.NEW_UPGRADE_USER && <NewUpgradeUser migrationData={migrationData} />}
        {PENDING_LOGIN_CASES.includes(migrationData.loginCase) && <PendingMergeUserView migrationData={migrationData} />}
      </StyledPanel>
    </Container>
  );
});

Migrated.displayName = 'Migrated';

Migrated.propTypes = {
  migrationData: PropTypes.shape({
    loginCase: PropTypes.oneOf(Object.keys(MIGRATED_LOGIN_CASE)).isRequired,
    emailAddress: PropTypes.string,
    mobile: PropTypes.string,
    upgradeEmailAddress: PropTypes.string,
    upgradeMobile: PropTypes.string,
  }).isRequired,
};

export default Migrated;
