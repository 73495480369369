/* eslint-disable import/prefer-default-export */
// Action Types:

export const ACTIONS = {
  deletePaymentMethod: 'paymentMethods/DELETE_PAYMENT_METHOD',
  deletePaymentMethodSuccess: 'paymentMethods/DELETE_PAYMENT_METHOD_SUCCESS',
  deletePaymentMethodFail: 'paymentMethods/DELETE_PAYMENT_METHOD_FAIL',
  deletedPaymentMethod: 'paymentMethods/DELETED_PAYMENT_METHOD',
  getAccount: 'account/GET_ACCOUNT',
  getAccountSuccess: 'account/GET_ACCOUNT_SUCCESS',
  getAccountFail: 'account/GET_ACCOUNT_FAIL',
  getApplications: 'applications/GET_APPLICATIONS',
  getApplicationsSuccess: 'applications/GET_APPLICATIONS_SUCCESS',
  getApplicationsFail: 'applications/GET_APPLICATIONS_FAIL',
  getDocuments: 'documents/GET_DOCUMENTS',
  getDocumentsSuccess: 'documents/GET_DOCUMENTS_SUCCESS',
  getDocumentsFail: 'documents/GET_DOCUMENTS_FAIL',
  getLoans: 'loans/GET_LOANS',
  getLoansSuccess: 'loans/GET_LOANS_SUCCESS',
  getLoansFail: 'loans/GET_LOANS_FAIL',
  getPaymentMethods: 'paymentMethods/GET_PAYMENT_METHODS',
  getPaymentMethodsSuccess: 'paymentMethods/GET_PAYMENT_METHODS_SUCCESS',
  getPaymentMethodsFail: 'paymentMethods/GET_PAYMENT_METHODS_FAIL',

  selectedPaymentMethod: 'paymentMethods/SELECTED_PAYMENT_METHOD',
  // login: 'authentication/LOGIN',
  // loginSuccess: 'authentication/LOGIN_SUCCESS',
  // loginChallenge: 'authentication/LOGIN_CHALLENGE',
  // loginReset: 'authentication/LOGIN_RESET',
  // loginStartOver: 'authentication/LOGIN_START_OVER',
  // loginFail: 'authentication/LOGIN_FAIL',
  // loginChallengeAnswer: 'authentication/LOGIN_CHALLENGE_ANSWER',
  // loginChallengeAnswerSuccess: 'authentication/LOGIN_CHALLENGE_ANSWER_SUCCESS',
  // loginChallengeAnswerFail: 'authentication/LOGIN_CHALLENGE_ANSWER_FAIL',
  // loginChallengeAnswerReset: 'authentication/LOGIN_CHALLENGE_ANSWER_RESET',
  ensureUser: 'authentication/ENSURE_USER',
  ensureUserSuccess: 'authentication/ENSURE_USER_SUCCESS',
  ensureUserFail: 'authentication/ENSURE_USER_FAIL',
  loginDismissErrors: 'authentication/LOGIN_DISMISS_ERRORS',
  logout: 'authentication/LOGOUT',
  logoutSuccess: 'authentication/LOGOUT_SUCCESS',
  logoutFail: 'authentication/LOGOUT_FAIL',
  logoutInitial: 'authentication/LOGOUT_INITIAL',
  logoutInitialSuccess: 'authentication/LOGOUT_INITIAL_SUCCESS',
  makePayment: 'loans/MAKE_PAYMENT',
  paymentMethodBinLookup: 'secureForms/PAYMENT_METHOD_BIN_LOOKUP',
  paymentMethodBinLookupResult: 'secureForms/PAYMENT_METHOD_BIN_LOOKUP_RESULT',
  paymentMethodCancel: 'secureForms/PAYMENT_METHOD_CANCEL',
  paymentMethodEdit: 'secureForms/PAYMENT_METHOD_EDIT',
  paymentMethodSetCardType: 'secureForms/PAYMENT_METHOD_SET_CARD_TYPE',
  paymentMethodSetCardValid: 'secureForms/PAYMENT_METHOD_SET_CARD_VALID',
  paymentMethodSetup: 'secureForms/PAYMENT_METHOD_SETUP',
  paymentMethodSubmit: 'secureForms/PAYMENT_METHOD_SUBMIT',
  paymentMethodSubmitSuccess: 'secureForms/PAYMENT_METHOD_SUBMIT_SUCCESS',
  paymentMethodSubmitFail: 'secureForms/PAYMENT_METHOD_SUBMIT_FAIL',
  postAuthorize: 'paymentMethods/POST_AUTHORIZE',
  postAuthorizeSuccess: 'paymentMethods/POST_AUTHORIZE_SUCCESS',
  postAuthorizeFail: 'paymentMethods/POST_AUTHORIZE_FAIL',
  postAutoPayInitialize: 'autopay/POST_AUTOPAY_INITIALIZE',
  postAutoPayInitializeReset: 'autopay/POST_AUTOPAY_INITIALIZE_RESET',
  postAutoPayInitializeSuccess: 'autopay/POST_AUTOPAY_INITIALIZE_SUCCESS',
  postAutoPayInitializeFail: 'autopay/POST_AUTOPAY_INITIALIZE_FAIL',
  postLoanAutoPayInitialize: 'autopay/POST_LOAN_AUTOPAY_INITIALIZE',
  postLoanAutoPayInitializeBatch: 'autopay/POST_LOAN_AUTOPAY_INITIALIZE_BATCH',
  postLoanAutoPayInitializeBatchDone: 'autopay/POST_LOAN_AUTOPAY_INITIALIZE_BATCH_DONE',
  postLoanAutoPayInitializeReset: 'autopay/POST_LOAN_AUTOPAY_INITIALIZE_RESET',
  postLoanAutoPayInitializeSuccess: 'autopay/POST_LOAN_AUTOPAY_INITIALIZE_SUCCESS',
  postLoanAutoPayInitializeFail: 'autopay/POST_LOAN_AUTOPAY_INITIALIZE_FAIL',
  postDelayPayment: 'loans/POST_DELAY_PAYMENT',
  postDelayPaymentSuccess: 'loans/POST_DELAY_PAYMENT_SUCCESS',
  postDelayPaymentFail: 'loans/POST_DELAY_PAYMENT_FAIL',
  postDelayPaymentReset: 'loans/POST_DELAY_PAYMENT_RESET',
  postHelpMessage: 'help/POST_HELP_MESSAGE',
  postHelpMessageSuccess: 'help/POST_HELP_MESSAGE_SUCCESS',
  postHelpMessageFail: 'help/POST_HELP_MESSAGE_FAIL',
  postMakePayment: 'loans/POST_MAKE_PAYMENT',
  postMakePaymentRefresh: 'loans/POST_MAKE_PAYMENT_REFRESH',
  // postMakePaymentBlock: 'loans/POST_MAKE_PAYMENT_BLOCK',
  // postMakePaymentBlockDismiss: 'loans/POST_MAKE_PAYMENT_BLOCK_DISMISS',
  postMakePaymentSuccess: 'loans/POST_MAKE_PAYMENT_SUCCESS',
  postMakePaymentFail: 'loans/POST_MAKE_PAYMENT_FAIL',
  postMakePaymentReset: 'loans/POST_MAKE_PAYMENT_RESET',
  postCalculatePayoff: 'loans/POST_CALCULATE_PAYOFF',
  postCalculatePayoffReset: 'loans/POST_CALCULATE_PAYOFF_RESET',
  postCalculatePayoffSuccess: 'loans/POST_CALCULATE_PAYOFF_SUCCESS',
  postCalculatePayoffFail: 'loans/POST_CALCULATE_PAYOFF_FAIL',
  postAccountUpdateInitialize: 'account/POST_ACCOUNT_UPDATE_INITIALIZE',
  postAccountUpdateInitializeSuccess: 'account/POST_ACCOUNT_UPDATE_INITIALIZE_SUCCESS',
  postAccountUpdateInitializeFail: 'account/POST_ACCOUNT_UPDATE_INITIALIZE_FAIL',
  postAccountUpdateVerify: 'account/POST_ACCOUNT_UPDATE_VERIFY',
  postAccountUpdateVerifySuccess: 'account/POST_ACCOUNT_UPDATE_VERIFY_SUCCESS',
  postAccountUpdateVerifyFail: 'account/POST_ACCOUNT_UPDATE_VERIFY_FAIL',
  updatePrimaryContact: 'account/UPDATE_PRIMARY_CONTACT',

  associatePaymentMethodsBatch: 'paymentMethods/ASSOCIATE_PAYMENT_METHOD_BATCH',
  associatePaymentMethodsBatchDone: 'paymentMethods/ASSOCIATE_PAYMENT_METHOD_BATCH_DONE',
  postPersist: 'paymentMethods/POST_PERSIST',
  postPersistSuccess: 'paymentMethods/POST_PERSIST_SUCCESS',
  postPersistFail: 'paymentMethods/POST_PERSIST_FAIL',
  postPersistReset: 'paymentMethods/POST_PERSIST_RESET',
  postTokenize: 'paymentMethods/POST_TOKENIZE',
  postTokenizeSuccess: 'paymentMethods/POST_TOKENIZE_SUCCESS',
  postTokenizeFail: 'paymentMethods/POST_TOKENIZE_FAIL',
  postVerify: 'paymentMethods/POST_VERIFY',
  postVerifySuccess: 'paymentMethods/POST_VERIFY_SUCCESS',
  postVerifyFail: 'paymentMethods/POST_VERIFY_FAIL',
  postVerifyReset: 'paymentMethods/POST_VERIFY_RESET',
  postPlaidLinkToken: 'paymentMethods/POST_PLAID_LINK_TOKEN',
  postPlaidLinkTokenSuccess: 'paymentMethods/POST_PLAID_LINK_TOKEN_SUCCESS',
  postPlaidLinkTokenFail: 'paymentMethods/POST_PLAID_LINK_TOKEN_FAIL',
  postPlaidLinkTokenStore: 'paymentMethods/POST_PLAID_LINK_TOKEN_STORE',
  postPlaidLinkTokenReset: 'paymentMethods/POST_PLAID_LINK_TOKEN_RESET',
  paymentMethodPersistAutoPay: 'paymentMethods/PAYMENT_METHOD_PERSIST_AUTOPAY',
  postLoanAutoPayPersist: 'autopay/POST_LOAN_AUTOPAY_PERSIST',
  postLoanAutoPayPersistReset: 'autopay/POST_LOAN_AUTOPAY_PERSIST_RESET',
  postLoanAutoPayPersistSuccess: 'autopay/POST_LOAN_AUTOPAY_PERSIST_SUCCESS',
  postLoanAutoPayPersistFail: 'autopay/POST_LOAN_AUTOPAY_PERSIST_FAIL',
  putAutoPayPersist: 'autopay/PUT_AUTOPAY_PERSIST',
  putAutoPayPersistReset: 'autopay/PUT_AUTOPAY_PERSIST_RESET',
  putAutoPayPersistSuccess: 'autopay/PUT_AUTOPAY_PERSIST_SUCCESS',
  putAutoPayPersistFail: 'autopay/PUT_AUTOPAY_PERSIST_FAIL',
  saveHelpDraft: 'help/SAVE_HELP_DRAFT',
  setPaymentMethodPrefillLoans: 'paymentMethods/SET_PAYMENT_METHOD_PREFILL_LOANS',
  setShowPaymentMethodFail: 'paymentMethods/SET_SHOW_FAIL',
  setShowPaymentMethodSuccess: 'paymentMethods/SET_SHOW_SUCCESS',
  setShowPaymentMethodAssociationFailure: 'paymentMethods/SET_SHOW_ASSOCIATION_FAILURE',
  setShowPaymentMethodAssociationSuccess: 'paymentMethods/SET_SHOW_ASSOCIATION_SUCCESS',
  signUp: 'authentication/SIGN_UP',
  signUpFail: 'authentication/SIGN_UP_FAIL',
  signUpStartOver: 'authentication/SIGN_UP_START_OVER',
  signIn: 'authentication/SIGN_IN',
  signInChallenge: 'authentication/SIGN_IN_CHALLENGE',
  signInFail: 'authentication/SIGN_IN_FAIL',
  signInSuccess: 'authentication/SIGN_IN_SUCCESS',
  signInChallengeAnswer: 'authentication/SIGN_IN_CHALLENGE_ANSWER',
  signInChallengeAnswerFail: 'authentication/SIGN_IN_CHALLENGE_ANSWER_FAIL',
  signInChallengeAnswerReset: 'authentication/SIGN_IN_CHALLENGE_ANSWER_RESET',
  signInChallengeAnswerSuccess: 'authentication/SIGN_IN_CHALLENGE_ANSWER_SUCCESS',

  adminSignIn: 'authentication/ADMIN_SIGN_IN',
  resetAccount: 'account/RESET_ACCOUNT',
  resetAccountUpdate: 'account/RESET_ACCOUNT_UPDATE',
  resetAccountUpdateInitialize: 'account/RESET_ACCOUNT_UPDATE_INITIALIZE',
  resetAccountUpdateVerify: 'account/RESET_ACCOUNT_UPDATE_VERIFY',

  // SCHEDULE PAYMENT
  postSchedulePaymentSchedule: 'loans/POST_SCHEDULE_PAYMENT_SCHEDULE',
  postSchedulePaymentScheduleSuccess: 'loans/POST_SCHEDULE_PAYMENT_SCHEDULE_SUCCESS',
  postSchedulePaymentScheduleFail: 'loans/POST_SCHEDULE_PAYMENT_SCHEDULE_FAIL',
  postSchedulePaymentReset: 'loans/POST_SCHEDULE_PAYMENT_RESET',

  deleteSchedulePayment: 'loans/DELETE_SCHEDULE_PAYMENT',
  deleteSchedulePaymentSuccess: 'loans/DELETE_SCHEDULE_PAYMENT_SUCCESS',
  deleteSchedulePaymentFail: 'loans/DELETE_SCHEDULE_PAYMENT_FAIL',

  // AUTOPAY MODAL
  setAutoPayModalState: 'autopay/OPEN_AUTOPAY_MODAL_STATE',
  setAutoPayAuthorizationModalState: 'autopay/SET_AUTO_PAY_AUTHORIZATION_MODAL_STATE',

  // BOOST
  getBoostInfo: 'boost/GET_BOOST_INFO',
  getBoostInfoSuccess: 'boost/GET_BOOST_INFO_SUCCESS',
  getBoostInfoFail: 'boost/GET_BOOST_INFO_FAIL',

  // AUTOPAY BY LOAN
  addLoanAutoPayDocument: 'loans/ADD_AUTO_PAY_DOCUMENT',
  disableLoanAutoPay: 'loans/DISABLE_LOAN_AUTO_PAY',
  enableLoanAutoPay: 'loans/ENABLE_LOAN_AUTO_PAY',
  putLoanPaymentMethodReset: 'loan/PUT_PAYMENT_METHOD_RESET',
  putLoanPaymentMethod: 'loans/PUT_PAYMENT_METHOD',
  putLoanPaymentMethodFail: 'loans/PUT_PAYMENT_METHOD_FAIL',
  putLoanPaymentMethodSuccess: 'loans/PUT_PAYMENT_METHOD_SUCCESS',
  setShowAutoPayFail: 'loans/SET_SHOW_AUTO_PAY_FAIL',
  setShowAutoPaySuccess: 'loans/SET_SHOW_AUTO_PAY_SUCCESS',
  updateLoanPaymentMethod: 'loans/UPDATE_LOAN_PAYMENT_METHOD',

  // ENROLL HARDSHIP
  postEnrollHardship: 'loans/POST_ENROLL_HARDSHIP',
  postEnrollHardshipFail: 'loans/POST_ENROLL_HARDSHIP_FAIL',
  postEnrollHardshipSuccess: 'loans/POST_ENROLL_HARDSHIP_SUCCESS',
  postEnrollHardshipReset: 'loans/POST_ENROLL_HARDSHIP_RESET',

  // Dismiss logged out message
  dismissLoggedOutMessage: 'account/DISMISSED_LOGGED_OUT_MESSAGE',

  // MIGRATION
  setMigrationAccount: 'account/SET_MIGRATION_ACCOUNT',
};
