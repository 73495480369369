import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// import { MaterialIcon } from 'styles/Icons';

import { isAdminUserSelector } from 'state/auth/selectors';
import { applicationsLoadingSelector, hasApplicationsSelector } from 'state/applications/selectors';
import { hasLoansSelector, loansLoadingSelector } from 'state/loans/selectors';
import { boostEnabledSelector } from 'state/boost/selectors';
import { getMigrationStatus } from 'state/account/selectors';
import SignOut from 'views/authenticator/components/SignOut';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import upliftLogo from 'lib/assets/images/uplift-logo.png';

import Theme from 'styles/Theme';
import { StyledLink } from 'styles/CommonStyles';
import { MIGRATION_STATUS } from 'lib/constants/migrationStatuses';

// const Notification = styled.div`
//   display: flex;

//   position: relative;
//   min-height: 66px;
//   width: 66px;
// `;

// const NotificationCount = styled.div`
//   pointer-events: none;
//   position: absolute;
//   top: 15px;
//   right: 15px;

//   background-color: #de350b;
//   color: #fff;

//   border-radius: 8px;
//   font-size: 12px;
//   line-height: 1;
//   min-width: 1px;
//   padding: 3px 6px;
//   text-align: center;
// `;

// const StyledButton = styled.button`
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   border-radius: 100%;
//   margin: 8px;

//   &:hover {
//     background-color: ${(props) => props.theme.colors.upliftLightestTeal};
//   }
// `;

const StyledHeader = styled.div`
  visibility: ${(props) => (props.isMigrating ? 'hidden' : 'visible')};
  background-color: ${(props) => (props.backgroundColor ? props.theme.colors.backgroundAdmin : '#fff')};
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);

  width: 100%;
  min-height: 60px;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${(props) => (props.isMigrating ? 'none' : '20px')};
  padding-left: 8px;
  padding-right: 8px;
`;

const StyledNavbar = styled(Navbar)`
  /* min-height: 60px; */
  padding: 0; /* Overwrite padding from Navbar*/
`;

const StyledNavLink = styled(StyledLink)`
  border-bottom: 3px solid transparent;
`;

const UpliftLogo = styled.img`
  width: 84px;
`;

// const feed = document.getElementById('feed');

const Header = memo(() => {
  const { t } = useTranslation();

  const isAdmin = useSelector(isAdminUserSelector);
  const migrationStatus = useSelector(getMigrationStatus);
  const hasApplications = useSelector(hasApplicationsSelector);
  const applicationsLoading = useSelector(applicationsLoadingSelector);
  const hasLoans = useSelector(hasLoansSelector);
  const loansLoading = useSelector(loansLoadingSelector);
  const hasBoost = useSelector(boostEnabledSelector);

  const activeTabStyle = {
    backgroundColor: isAdmin ? Theme.colors.backgroundAdminActive : Theme.colors.upliftLightestTeal,
    borderBottom: `3px solid ${Theme.colors.upliftTeal}`,
  };

  const adminColor = isAdmin ? Theme.colors.backgroundAdminActive : null;
  return (
    <StyledHeader id="Header" backgroundColor={isAdmin} isMigrating={migrationStatus in MIGRATION_STATUS}>
      <StyledNavbar id="NavBar" expand="sm">
        <Navbar.Brand href="/account">
          <UpliftLogo src={upliftLogo} alt="Uplift Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <StyledNavLink activeStyle={activeTabStyle} to="/account" color={adminColor}>
              {t('header.navigation.account')}
            </StyledNavLink>

            {(!!loansLoading || hasLoans) && (
              <StyledNavLink activeStyle={activeTabStyle} to="/loans" color={adminColor}>
                {t('header.navigation.loans')}
              </StyledNavLink>
            )}

            {(!!applicationsLoading || hasApplications) && (
              <StyledNavLink activeStyle={activeTabStyle} to="/applications" color={adminColor}>
                {t('header.navigation.applications')}
              </StyledNavLink>
            )}

            {hasBoost && (
              <StyledNavLink activeStyle={activeTabStyle} to="/boost" color={adminColor}>
                {t('header.navigation.boost')}
              </StyledNavLink>
            )}

            <StyledNavLink activeStyle={activeTabStyle} to="/help" color={adminColor}>
              {t('header.navigation.help')}
            </StyledNavLink>
            <SignOut activeStyle={activeTabStyle} to="/signin" color={adminColor}>
              {t('header.navigation.sign_out')}
            </SignOut>
          </Nav>
        </Navbar.Collapse>
      </StyledNavbar>
    </StyledHeader>
  );
});

Header.displayName = 'Header';

export default Header;
