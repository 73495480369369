/* eslint-disable import/prefer-default-export */
export const MIGRATION_STATUS = {
  MIGRATING: 'MIGRATING',
  MIGRATED: 'MIGRATED',
};

export const MIGRATED_LOGIN_CASE = {
  EXISTING_UPGRADE_USER: 'EXISTING_UPGRADE_USER',
  NEW_UPGRADE_USER: 'NEW_UPGRADE_USER',
  PENDING_USER: 'PENDING_USER',
  PII_DISCREPANCIES: 'PII_DISCREPANCIES',
};
