/* eslint-disable no-shadow */
import { useCallback, useState } from 'react';

export const useStorage = (storage, key, initialValue) => {
  const [value, setState] = useState(() => {
    const value = storage.getItem(key); // NOSONAR
    return value && value !== 'undefined' ? JSON.parse(value) : initialValue;
  });
  const setValue = useCallback(
    // prettier-ignore
    (value) => { // NOSONAR
      storage.setItem(key, JSON.stringify(value));
      setState(value);
    },
    [key, storage]
  );
  return [value, setValue];
};

export default useStorage;
