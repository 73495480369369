import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useStorage } from 'hooks/useStorage';
import { loansWithoutPaymentMethodsSelector } from 'state/loans/selectors';
import { MaterialIcon } from 'styles/Icons';
import theme from 'styles/Theme';
import { disabledHyperLinkStyles } from 'styles/CommonStyles';
import { accountIdSelector } from 'state/account/selectors';
import { useHistory } from 'react-router-dom';
import { getNextPaymentDate } from 'views/account/wallet/components/LoanSummarySelection';
import { orderBy } from 'lodash';

const StyledBanner = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.warningYellow};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderBrown};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  min-height: 70px;
  padding: 1rem;
`;

const StyledMaterialIcon = styled(MaterialIcon)`
  margin-left: auto;
  padding-right: 8px;
  vertical-align: text-bottom;
`;

const StyledMessage = styled.span`
  color: ${(props) => props.theme.colors.iconGrey};
  text-align: center;
  font-size: 0.85rem;
`;

const StyledCloseButton = styled.button`
  background-color: ${(props) => props.theme.colors.warningYellow};
  border: none;
  cursor: pointer;
  margin-left: auto;
  outline: none;
  padding-top: 5px;
`;

const StyledHyperlink = styled.button`
  color: ${(props) => props.theme.colors.hyperlinkBlue};
  cursor: pointer;
  background: none;
  border: none;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 24px;

  &:hover {
    color: ${(props) => props.theme.colors.hyperlinkBlueHover};
    text-decoration: underline;
  }

  ${(props) => props.disabled && disabledHyperLinkStyles}
`;

const MissingPaymentMethodBanner = memo(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [display, setDisplay] = useStorage(sessionStorage, 'dismissMissingPaymentMethodBanner');
  const accountId = useSelector(accountIdSelector);
  const loansWithoutPaymentMethods = useSelector(loansWithoutPaymentMethodsSelector);

  const sortedLoans = orderBy(
    loansWithoutPaymentMethods?.map((loan) => {
      const { paymentHistory, order, loanId } = loan;
      const nextPaymentDate = getNextPaymentDate(loan, paymentHistory, order);
      return {
        loanId,
        nextPaymentDate,
      };
    }),
    ['nextPaymentDate']
  );

  const handleOnAddPayment = () => {
    const { loanId } = sortedLoans[0];
    history.push(`/loans#${loanId}`);
  };

  const onClose = () => {
    if (accountId) {
      setDisplay(accountId);
    }
  };

  return loansWithoutPaymentMethods?.length > 0 && display !== accountId ? (
    <StyledBanner>
      <StyledMaterialIcon name="warning" color={theme.colors.warningBrown} />
      <StyledMessage>
        {t('banner.add_a_payment_method.message')}&nbsp;
        <StyledHyperlink onClick={handleOnAddPayment}>{t('banner.add_a_payment_method.hyperlink')}</StyledHyperlink>
      </StyledMessage>
      <StyledCloseButton type="button" onClick={onClose}>
        <MaterialIcon name="close" size="24px" />
      </StyledCloseButton>
    </StyledBanner>
  ) : null;
});

MissingPaymentMethodBanner.displayName = 'MissingPaymentMethodBanner';

export default MissingPaymentMethodBanner;
