import React, { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { StyledRow, Column, MigratedStyledText } from 'styles/CommonStyles';
import PropTypes from 'prop-types';
import CalendarIcon from 'lib/assets/images/new_name_same_service.svg';
import LockIcon from 'lib/assets/images/password changed.svg';
import PhoneCheckMark from 'lib/assets/images/quick_and_easy_application.svg';
import { MIGRATED_LOGIN_CASE } from 'lib/constants/migrationStatuses';

const StyledIcon = styled.img`
  height: 56px;
  width: 56px;
`;

const getTranslationKey = (loginCase, isSubCaption) => {
  let translationKey;
  switch (loginCase) {
    case 'EXISTING_UPGRADE_USER':
      if (isSubCaption) {
        translationKey = 'account.existing_upgrade_user.info_diagram_subcaptions';
      } else {
        translationKey = 'account.existing_upgrade_user.info_diagram';
      }
      break;
    case 'NEW_UPGRADE_USER':
      if (isSubCaption) {
        translationKey = 'account.new_upgrade_user.info_diagram_subcaptions';
      } else {
        translationKey = 'account.new_upgrade_user.info_diagram';
      }
      break;
    case 'PENDING_USER':
      if (isSubCaption) {
        translationKey = 'account.pending_merge_user.info_diagram_subcaptions';
      } else {
        translationKey = 'account.pending_merge_user.info_diagram';
      }
      break;
    case 'PII_DISCREPANCIES':
      if (isSubCaption) {
        translationKey = 'account.pending_merge_user.info_diagram_subcaptions';
      } else {
        translationKey = 'account.pending_merge_user.info_diagram';
      }
      break;
    default:
      break;
  }
  return translationKey;
};

const InfoDiagram = memo(({ loginCase }) => {
  const { t } = useTranslation();
  const steps = t(getTranslationKey(loginCase), { returnObjects: true });
  const stepsSubcaptions = t(getTranslationKey(loginCase, true), { returnObjects: true });
  return (
    <Column gap="24px">
      {['one', 'two', 'three'].map((key, index) => (
        <StyledRow>
          {loginCase === MIGRATED_LOGIN_CASE.NEW_UPGRADE_USER && (
            <>
              {index === 0 && <StyledIcon src={PhoneCheckMark} />}
              {index === 1 && <StyledIcon src={LockIcon} />}
              {index === 2 && <StyledIcon src={CalendarIcon} />}
            </>
          )}
          {loginCase === MIGRATED_LOGIN_CASE.EXISTING_UPGRADE_USER && (
            <>
              {index === 0 && <StyledIcon src={CalendarIcon} />}
              {index === 1 && <StyledIcon src={LockIcon} />}
              {index === 2 && <StyledIcon src={PhoneCheckMark} />}
            </>
          )}
          {loginCase === MIGRATED_LOGIN_CASE.PENDING_USER && (
            <>
              {index === 0 && <StyledIcon src={CalendarIcon} />}
              {index === 1 && <StyledIcon src={LockIcon} />}
              {index === 2 && <StyledIcon src={PhoneCheckMark} />}
            </>
          )}
          {loginCase === MIGRATED_LOGIN_CASE.PII_DISCREPANCIES && (
            <>
              {index === 0 && <StyledIcon src={CalendarIcon} />}
              {index === 1 && <StyledIcon src={LockIcon} />}
              {index === 2 && <StyledIcon src={PhoneCheckMark} />}
            </>
          )}
          <Column gap="12px">
            <Trans>
              <MigratedStyledText fontWeight={500}>{steps[key]}</MigratedStyledText>
              <MigratedStyledText>{stepsSubcaptions[key]}</MigratedStyledText>
            </Trans>
          </Column>
        </StyledRow>
      ))}
    </Column>
  );
});

InfoDiagram.displayName = 'InfoDiagram';

InfoDiagram.propTypes = {
  loginCase: PropTypes.string.isRequired,
};

export default InfoDiagram;
