import { MIGRATION_STATUS } from 'lib/constants/migrationStatuses';
import { setMigrationAccount } from 'state/account/actions';

const MigrationInterceptor = (error, dispatch) => {
  const { response } = error;
  const { data: errorResponse } = response;

  // Backend will return 503 for Migration Maintenance
  if (response?.status === 503 && errorResponse) {
    if (errorResponse?.migrationStatus in MIGRATION_STATUS) {
      const migrationStatus = errorResponse?.migrationStatus;
      const migrationData = errorResponse?.migrationData;

      if (migrationStatus === MIGRATION_STATUS.MIGRATING) {
        dispatch(setMigrationAccount(migrationStatus));
        return Promise.reject(error);
      }
      if (migrationStatus === MIGRATION_STATUS.MIGRATED) {
        dispatch(setMigrationAccount(migrationStatus, migrationData));
        return Promise.reject(error);
      }
    }
  }
  return null;
};

export default MigrationInterceptor;
