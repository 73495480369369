import React, { useCallback, useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import history from 'History.js'; // eslint-disable-line import/extensions

import { toKebabCase } from 'lib/utils';

import { LOAN_STATUS_CODES } from 'lib/constants/loanStatuses';
import { FFAM_PHONE_NUMBERS, TRUE_ACCORD_INFO, UPLIFT_PHONE_NUMBERS, loanSaleCompanies } from 'lib/constants/servicersInfo';

import { isCanadianSelector } from 'state/account/selectors';
import { resetCalculatePayoff } from 'state/loans/calculatePayoff/actions';

import PanelHeader from 'components/PanelHeader';
import PurchaseDetailsModal from 'views/loans/payments/modals/PurchaseDetailsModal';
import OverdueMessage from 'views/loans/schedulePayments/OverdueMessage';
import PaymentProgressBar from 'views/loans/components/PaymentProgressBar';
import SoldDebt from 'views/loans/components/SoldDebt';

import Document from 'views/documents/components/Document';
import { MaterialIcon } from 'styles/Icons';
import { Body, Panel, ButtonHyperLink, ButtonOutline } from 'styles/CommonStyles';
import { formatDate, formatMoneyFromCents } from 'lib/Formatters';
import { documentsSelector } from 'state/documents/selectors';
import LoanPayment from './components/LoanPayment';

import LoanInfo from './components/LoanInfo';
import LoanHardship from './components/LoanHardship';
import LoanHistory from './components/LoanHistory';
import LoanPaymentMethod from './components/LoanPaymentMethod';
import LoanSpacer from './components/LoanSpacer';
import LoanStatus from './components/LoanStatus';
import PayoffLetterModal from './components/PayoffLetterModal';

const NextPaymentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2.5rem;

  @media screen and (min-width: ${(props) => props.theme.minWidth.nano}) {
    font-size: 3rem;
  }
`;

const LoansHeader = styled.div`
  margin-bottom: 1rem;
  @media screen and (min-width: ${(props) => props.theme.minWidth.nano}) {
    margin-bottom: 1rem;
  }
`;

const MerchantIcon = styled.img`
  max-height: 38px;
  max-width: 38px;
  margin: 4px 18px 0 0;
`;

const MerchantInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const MonthlyPayment = styled.div`
  color: ${(props) => props.theme.colors.upliftTeal};

  font-size: 2.5rem;

  @media screen and (min-width: ${(props) => props.theme.minWidth.nano}) {
    font-size: 3rem;
  }
`;

const StyledButtonHyperLink = styled(ButtonHyperLink)`
  font-size: 0.9rem;
  font-weight: 400;
`;

const StyledPayoffLetterButton = styled(ButtonOutline)`
  display: block;
  margin: 0 auto;
  padding-left: 70px;
  padding-right: 70px;
  text-transform: uppercase;

  * + & {
    margin-top: 2.5rem;
  }
`;

const iconColor = '#dde1ea';
const iconSize = '2.4rem';

const AUTOPAY_DOC_TYPES = ['ach', 'autopay', 'dcd', 'dcd_CA'];

const checkIsAutopayDocument = (document) => AUTOPAY_DOC_TYPES.indexOf(document.documentType) !== -1;

// prettier-ignore
const LoansDetailsView = memo(({ loanDetails }) => { // NOSONAR
  const { i18n, t } = useTranslation();

  const {
    country,
    industry,
    loanDocuments,
    loanId,
    loanStatusCode,
    merchantInfo,
    monthlyPaymentAmount,
    order,
    paymentHistory,
    servicerName,
    state,
    term,
    remainingTerm,
  } = loanDetails;

  const dispatch = useDispatch();
  const [showPurchaseDetailsModal, setShowPurchaseDetailsModal] = useState(false);
  const [showPayoffLetterModal, setShowPayoffLetterModal] = useState(false);

  const isCanadian = useSelector(isCanadianSelector);

  const isPending = loanStatusCode === LOAN_STATUS_CODES.PENDING;
  const chargedOff = loanStatusCode === LOAN_STATUS_CODES.CHARGE_OFF || loanStatusCode === LOAN_STATUS_CODES.GL_CHARGE_OFF;
  const chargeoffDate = chargedOff ? formatDate(loanDetails.chargeoffDate) : '';
  const soldDebt = loanStatusCode === LOAN_STATUS_CODES.SOLD_DEBT;
  const soldDebtServicer =
    soldDebt && servicerName && loanSaleCompanies.find((company) => company.lpbsServicerName === servicerName);

  const ffamPhoneNumber = country === 'US' ? FFAM_PHONE_NUMBERS.US : FFAM_PHONE_NUMBERS.CA;

  const loanDocs = isCanadian ? loanDocuments.filter((doc) => doc.documentType !== 'csd') : loanDocuments;

  const accountDocs = useSelector(documentsSelector);

  const cpaDoc = isCanadian ? null : accountDocs?.filter((doc) => doc.documentType === 'cpa').pop();

  const nextPaymentOrOverdueDate = (() => {
    if (paymentHistory.find((x) => x.status === 'Next Payment')?.date) {
      return moment(paymentHistory.find((x) => x.status === 'Next Payment')?.date).format('LL');
    }
    if (paymentHistory.find((x) => x.status === 'Overdue')?.date) {
      return moment(paymentHistory.find((x) => x.status === 'Overdue')?.date).format('LL');
    }
    return null;
  })();

  const glyph =
    merchantInfo && merchantInfo.logoUrl ? (
      <MerchantIcon src={merchantInfo.logoUrl} alt="merchant-logo" />
    ) : (
      <MaterialIcon name="person" size={iconSize} color={iconColor} />
    );

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27 && showPurchaseDetailsModal === true) {
        // google analytics
        const gaEvent = {
          category: 'user',
          action: 'click',
          label: 'close-see-purchase-details-modal',
          value: 1,
        };
        trackEvent(gaEvent);

        // cevents
        let metadata = {};
        metadata.uplift_account_id = localStorage.getItem('account_id_hash');
        metadata.isAdmin = localStorage.getItem('adminAuth');
        metadata = toKebabCase(metadata);

        cevents.setLevel('debug');
        cevents.debugRaw('user', 'click', 'close-see-purchase-details-modal', metadata);


        setShowPurchaseDetailsModal(false);
      }
    },
    [showPurchaseDetailsModal]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const openModal = useCallback((event) => {
    event.preventDefault();

    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'click',
      label: 'open-see-trip-details-modal',
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata.isAdmin = localStorage.getItem('adminAuth');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);


    setShowPurchaseDetailsModal(true);
  }, []);

  const closeModal = useCallback((event) => {
    event.preventDefault();

    // google analytics
    const gaEvent = {
      category: 'user',
      action: 'click',
      label: 'close-see-trip-details-modal',
      value: 1,
    };
    trackEvent(gaEvent);

    // cevents
    let metadata = {};
    metadata.uplift_account_id = localStorage.getItem('account_id_hash');
    metadata.isAdmin = localStorage.getItem('adminAuth');
    metadata = toKebabCase(metadata);

    cevents.setLevel('debug');
    cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);


    setShowPurchaseDetailsModal(false);
  }, []);

  const autoPayDocs = loanDocs.filter(checkIsAutopayDocument);
  const latestAutoPayDoc = autoPayDocs.length
    ? autoPayDocs.sort((a, b) => {
      const firstDate = a.createdDate || a.documentDate || '';
      const secondDate = b.createdDate || b.documentDate || '';
      return firstDate.localeCompare(secondDate);
    }).pop()
    : null;
  const canRequestPayoff =
    loanDetails.state?.toUpperCase() === 'ACTIVE' && 
    [LOAN_STATUS_CODES.GL_CHARGE_OFF, LOAN_STATUS_CODES.SOLD_DEBT].indexOf(loanDetails.loanStatusCode) === -1;
  const isRequestPayoffDisabled = loanDetails.state?.toUpperCase() === 'ACTIVE' &&
    loanDetails.loanStatusCode === LOAN_STATUS_CODES.PENDING;

  return (
    <Panel>
      <LoansHeader id={loanId} data-testid="loans-header">
        <PanelHeader
          glyph={glyph}
          labelLeft={
            <>
              <span>{t('loans.header.purchased_from')}</span>
            </>
          }
          contentLeft={
            <MerchantInfo>
              {merchantInfo?.name}
              <StyledButtonHyperLink data-testid="see-trip-details" onClick={openModal}>
                {t('loans.merchant.see_purchase_details')}
              </StyledButtonHyperLink>
            </MerchantInfo>
          }
          labelRight={t('loans.header.loan_id')}
          contentRight={loanId}
          footerRight={<LoanStatus loanStatusCode={loanStatusCode} state={state} hardshipStatus={loanDetails.hardshipEligibility?.status} />}
        />
      </LoansHeader>

      {showPurchaseDetailsModal && (
        <PurchaseDetailsModal merchantInfo={merchantInfo} closeModal={closeModal} order={order} industry={industry} />
      )}

      {chargedOff && servicerName === 'FFAM' && (
        <Body>
          <OverdueMessage
            header={
              <Trans i18nKey="loans.history.ffam_charged_off_p1">
                Your Uplift loan is more than 120 days late and has been charged off as of {{ chargeoffDate }}.
              </Trans>
            }
            message={
              <Trans i18nKey="loans.history.ffam_charged_off_p2">
                Your loan has been placed with First Financial Asset Management (FFAM), a collections agency. Please contact (FFAM)
                at {{ ffamPhoneNumber }} with questions or to set up a payment plan.
              </Trans>
            }
          />
        </Body>
      )}

      {chargedOff && servicerName === 'TrueAccord Recover' && (
        <Body>
          <OverdueMessage
            header={
              <Trans i18nKey="loans.history.ffam_charged_off_p1">
                Your Uplift loan is more than 120 days late and has been charged off as of {{ chargeoffDate }}.
              </Trans>
            }
            message={
              <Trans i18nKey="loans.history.true_accord_charged_off">
                Your loan has been placed with True Accord, a collections agency. Please contact True Accord at
                <a href={TRUE_ACCORD_INFO.phoneHref}>{{ taPhoneNumber: TRUE_ACCORD_INFO.phone }}</a>
                with questions or to set up a payment plan.
              </Trans>
            }
          />
        </Body>
      )}

      {chargedOff && servicerName !== 'FFAM' && servicerName !== 'TrueAccord Recover' && (
        <Body>
          <OverdueMessage
            header={t('loans.history.charged_off_p1')}
            message={
              <Trans i18nKey="loans.history.charged_off_p2">
                Please contact Uplift customer service at
                <a href={UPLIFT_PHONE_NUMBERS.customerServiceHref}>{{ upliftPhoneNumber: UPLIFT_PHONE_NUMBERS.customerService }}</a>
                for account information or to make a payment.
              </Trans>
            }
          />
        </Body>
      )}

      {soldDebtServicer && (
        <SoldDebt
          company={soldDebtServicer.info.company}
          phoneHref={soldDebtServicer.info.phoneHref}
          phoneNumber={soldDebtServicer.info.phoneNumber}
          shortName={soldDebtServicer.info.shortName}
          url={soldDebtServicer.info.url}
          urlHref={soldDebtServicer.info.urlHref}
        />
      )}

      <Body className="pb-0">
        <NextPaymentSection>
          <MonthlyPayment data-testid="monthly-payment">
            {formatMoneyFromCents(monthlyPaymentAmount?.amount, true, i18n.language)}/{t('loans.details.month')}
          </MonthlyPayment>
        </NextPaymentSection>
      </Body>

      {loanStatusCode === LOAN_STATUS_CODES.PAID_OFF && (
        <Body>
          <PaymentProgressBar monthlyPaymentCount={term} term={term} remainingTerm={remainingTerm} />
        </Body>
      )}

      {(loanDetails.servicerName === 'Uplift' || !chargedOff) && (
        <>
          {state.toUpperCase() !== 'CLOSED' ? (
            <LoanPayment loanDetails={loanDetails} nextPaymentOrOverdueDate={nextPaymentOrOverdueDate} />
          ) : null}
        </>
      )}

      <Body>
        <LoanInfo loanDetails={loanDetails} />
      </Body>

      <>
        <LoanSpacer dataTestId="payment-method" iconName="account_balance_wallet" iconTheme={null} headerName={t('loans.payment.form.payment_method')} />
        <LoanPaymentMethod loan={loanDetails} />
      </>

      {((loanDetails.servicerName === 'Uplift' || !chargedOff) && !isPending) && (
        <>
          <LoanSpacer
            dataTestId="history"
            iconName="schedule"
            headerName={t('loans.history.payment_activity')}
            viewStatement
            loanDetails={loanDetails}
          />
          <LoanHistory loanDetails={loanDetails} loanState={state} />

          <LoanHardship loan={loanDetails} />
        </>
      )}
      <div id={`${loanId}/docs`}>
        <LoanSpacer dataTestId="documents" iconName="insert_drive_file" headerName={t('documents.documents')} />
      </div>
      <Body>
        {cpaDoc && <Document location="" id="account" document={cpaDoc} />}
        {latestAutoPayDoc && <Document key={`autopay-doc-${loanId}`} id={loanId} document={latestAutoPayDoc} location="loans" />}
        {loanDocs
          .filter((doc) => !checkIsAutopayDocument(doc))
          .map((doc, index) => {
            const docKey = `${doc.sha}-${index}`;
            return <Document key={docKey} id={loanId} document={doc} location="loans" />;
          })}
        {canRequestPayoff && (
          <StyledPayoffLetterButton disabled={isRequestPayoffDisabled} onClick={() => setShowPayoffLetterModal(true)}>
            {t('documents.payoff_letter.request_payoff_letter')}
          </StyledPayoffLetterButton>
        )}
      </Body>

      {showPayoffLetterModal && (
        <PayoffLetterModal
          onConfirm={(data) => {
            dispatch(resetCalculatePayoff());
            history.push(`/loans/${loanId}/payoff?days=${data.payoffLetterType}`);
          }}
          onClose={() => setShowPayoffLetterModal(false)}
        />
      )}
    </Panel>
  );
});

LoansDetailsView.displayName = 'LoansDetailsView';

LoansDetailsView.propTypes = {
  loanDetails: PropTypes.shape({
    chargeoffDate: PropTypes.string,
    country: PropTypes.string,
    isHardshipPayment: PropTypes.bool,
    hardshipEligibility: PropTypes.shape({
      status: PropTypes.string,
      minimumPaymentAmount: PropTypes.string,
    }),
    industry: PropTypes.string,
    loanDocuments: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    loanId: PropTypes.string,
    loanStatusCode: PropTypes.string,
    term: PropTypes.number,
    remainingTerm: PropTypes.number,
    merchantInfo: PropTypes.shape({
      contact: PropTypes.objectOf(PropTypes.string),
      logoUrl: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    monthlyPaymentAmount: PropTypes.shape({
      amount: PropTypes.number,
    }),
    order: PropTypes.shape({
      airReservations: PropTypes.arrayOf(PropTypes.object),
      cruiseReservations: PropTypes.arrayOf(PropTypes.object),
      hotelReservations: PropTypes.arrayOf(PropTypes.object),
      travelers: PropTypes.arrayOf(PropTypes.object),
    }),
    paymentHistory: PropTypes.arrayOf(PropTypes.shape({ amount: PropTypes.number })),
    paymentToken: PropTypes.string,
    servicerName: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
};

export default LoansDetailsView;
