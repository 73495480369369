/* eslint-disable react/no-danger */
import React, { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { MIGRATED_LOGIN_CASE } from 'lib/constants/migrationStatuses';
import styled from 'styled-components';
import { Accordion } from 'styles/CommonStyles';
import { MaterialIcon } from 'styles/Icons';

const Header = styled.div`
  color: ${(props) => props.theme.colors.contentGrey};
  font-family: ${(props) => props.theme.font.fontFamily.flexpay};
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  padding-bottom: 24px;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledContainer = styled.div`
  display: flex !important;
  flex-direction: column;
`;

const StyledExpandedContainer = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
`;

const StyledQuestion = styled.span`
  padding-left: 16px;
  color: ${(props) => props.theme.colors.contentGrey};
  font-family: ${(props) => props.theme.font.fontFamily.flexpay};
  background-color: none !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.09px;
  text-align: left;
`;

const StyledMaterialIcon = styled(MaterialIcon)`
  margin-left: auto;
  padding-right: 8px;
`;

const getTranslationKey = (loginCase) => {
  let translationKey;
  switch (loginCase) {
    case 'EXISTING_UPGRADE_USER':
      translationKey = 'account.existing_upgrade_user.faqs';
      break;
    case 'NEW_UPGRADE_USER':
      translationKey = 'account.new_upgrade_user.faqs';
      break;
    case 'PENDING_USER':
      translationKey = 'account.pending_merge_user.faqs';
      break;
    case 'PII_DISCREPANCIES':
      translationKey = 'account.pending_merge_user.faqs';
      break;
    default:
      break;
  }
  return translationKey;
};

const Root = styled.div`
  width: 100%;
  border: none;
  padding: 32px 16px;

  ${Accordion} {
    outline: none !important;
    padding: 16px 0;
  }

  ${Accordion.Toggle} {
    button:focus {
      outline: 2px solid ${(props) => props.theme.colors.hyperlinkBlueHover};
    }
  }

  ${Accordion}:first-child {
    padding-top: 0;
  }

  ${Accordion}:last-child {
    padding-bottom: 0;
  }
`;

const MerchantAccordionToggle = styled(Accordion.Toggle)`
  background: none;
  border: none;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderBottomLine};
`;

const DropdownItem = ({ loginCase, item, index }) => {
  const [isExpanded, setExpanded] = useState(false);
  const { question, answer } = item;
  const itemId = `${index}-${loginCase}`;
  const eventKeyId = `${index}-${question}`;

  return (
    <Accordion>
      <MerchantAccordionToggle
        className={MerchantAccordionToggle.c}
        eventKey={eventKeyId}
        aria-expanded={isExpanded}
        aria-controls={itemId}
        onClick={() => setExpanded(!isExpanded)}
      >
        <StyledQuestion>{question}</StyledQuestion>
        <StyledMaterialIcon name={isExpanded ? 'expand_less' : 'expand_more'} size="1.7em" />
      </MerchantAccordionToggle>
      <Accordion.Collapse eventKey={eventKeyId} id={itemId}>
        <StyledExpandedContainer>
          <span dangerouslySetInnerHTML={{ __html: answer }} />
        </StyledExpandedContainer>
      </Accordion.Collapse>
    </Accordion>
  );
};

const FAQ = memo(({ loginCase }) => {
  const { t } = useTranslation();
  const questions = t(getTranslationKey(loginCase), { returnObjects: true });

  return (
    <Root>
      <Header>{t('account.migration.have_questions')}</Header>
      <StyledContainer>
        {Object.entries(questions).map((item, index) => {
          return <DropdownItem loginCase={loginCase} item={item[1]} index={index} />;
        })}
      </StyledContainer>
    </Root>
  );
});

FAQ.displayName = 'FAQ';

DropdownItem.propTypes = {
  loginCase: PropTypes.oneOf(Object.keys(MIGRATED_LOGIN_CASE)).isRequired,
  item: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

FAQ.propTypes = {
  loginCase: PropTypes.oneOf(Object.keys(MIGRATED_LOGIN_CASE)).isRequired,
};

export default FAQ;
