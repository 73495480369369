import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Maintenance from 'lib/assets/images/maintainance.svg';

import Container from 'components/GlobalContainer';

const StyledMaintenance = styled.img`
  width: 80px;
  height: 80px;
`;

export const BoldText = styled.div`
  color: ${(props) => props.theme.colors.iconGrey};
  font-size: 16px;
  font-weight: ${(props) => (props.isBold ? 600 : 400)} !important;
`;

const MaintenanceFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const MigrationMaintenance = memo(() => {
  const { t } = useTranslation();
  return (
    <Container>
      <MaintenanceFlexContainer>
        <StyledMaintenance src={Maintenance} alt="Maintenance" />
        <BoldText isBold>{t('account.migration.maintenance')}</BoldText>
        <BoldText>{t('account.migration.restore')}</BoldText>
        <BoldText>{t('account.migration.try_again')}</BoldText>
      </MaintenanceFlexContainer>
    </Container>
  );
});

MigrationMaintenance.displayName = 'Migration Maintenance';

export default MigrationMaintenance;
