import styled from 'styled-components';

export default styled.div`
  background-color: ${(props) => {
    if (props.isMigrated) {
      return props.theme.colors.upliftLightestTeal;
    }
    return props.isWhiteBackground ? props.theme.colors.upliftWhite : props.theme.colors.backgroundLightBlue;
  }};
  font-family: ${(props) => props.theme.font.fontFamily.default};

  overflow: hidden;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  min-height: 100vh;
  min-width: 100%;
`;
