import React, { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  MigratedStyledText,
  NextStepsContainer,
  NextStepsIndex,
  Column,
  StyledListItem,
  StyledListText,
  SpecialDescription,
  StyledOutboundLink,
} from 'styles/CommonStyles';
import { MIGRATED_LOGIN_CASE } from 'lib/constants/migrationStatuses';
import { PropTypes } from 'prop-types';
import { BoldText } from 'views/migrationMaintenance/index';
import InfoDiagram from './InfoDiagram';
import FAQ from './FAQ';

const ExistingUpgradeUser = memo(({ migrationData }) => {
  const { t } = useTranslation();
  const steps = t('account.existing_upgrade_user.set_up_steps', { returnObjects: true });
  const { upgradeEmailAddress } = migrationData;

  const upgradeDashboardUrl = 'https://www.upgrade.com/auth/flexpay/enroll?method=email';
  if (upgradeEmailAddress) {
    return (
      <>
        <NextStepsContainer>
          <MigratedStyledText>
            <Trans i18nKey="account.existing_upgrade_user.manage_services" />
          </MigratedStyledText>
          <MigratedStyledText fontWeight={500}>{t('account.existing_upgrade_user.next_steps')}</MigratedStyledText>
          {Object.entries(steps).map((step, index) => (
            <StyledListItem>
              <NextStepsIndex>{index + 1}</NextStepsIndex>
              <Column>
                <StyledListText>{step[1]}</StyledListText>
                {index === 0 && (
                  <SpecialDescription>
                    <Trans i18nKey="account.existing_upgrade_user.your_email">
                      Your email on Upgrade:
                      <MigratedStyledText fontWeight={600}>{{ upgradeEmailAddress }}</MigratedStyledText>
                    </Trans>
                  </SpecialDescription>
                )}
              </Column>
            </StyledListItem>
          ))}
          <StyledOutboundLink href={upgradeDashboardUrl} target="_blank" rel="noopener noreferrer">
            {t('account.existing_upgrade_user.continue_to_upgrade')}
          </StyledOutboundLink>
        </NextStepsContainer>
        <InfoDiagram loginCase={MIGRATED_LOGIN_CASE.EXISTING_UPGRADE_USER} />
        <FAQ loginCase={MIGRATED_LOGIN_CASE.EXISTING_UPGRADE_USER} />
        <StyledOutboundLink href={upgradeDashboardUrl} target="_blank" rel="noopener noreferrer">
          {t('account.existing_upgrade_user.continue_to_upgrade')}
        </StyledOutboundLink>
      </>
    );
  }
  return (
    <>
      <BoldText isBold>{t('account.migration.maintenance')}</BoldText>
      <BoldText>{t('account.migration.restore')}</BoldText>
      <BoldText>{t('account.migration.try_again')}</BoldText>
    </>
  );
});

ExistingUpgradeUser.displayName = 'ExistingUpgradeUser';

ExistingUpgradeUser.propTypes = {
  migrationData: PropTypes.shape({
    loginCase: PropTypes.oneOf(Object.keys(MIGRATED_LOGIN_CASE)).isRequired,
    emailAddress: PropTypes.string,
    mobile: PropTypes.string,
    upgradeEmailAddress: PropTypes.string,
    upgradeMobile: PropTypes.string,
  }).isRequired,
};

export default ExistingUpgradeUser;
