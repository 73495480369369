import React, { useEffect, useCallback, memo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { MaterialIcon } from 'styles/Icons';
import theme from 'styles/Theme';
import moment from 'moment';
import { loansSelector } from 'state/loans/selectors';
import { ONBOARD_SPEND_UPCODES } from 'lib/constants/onboardSpendUpcodes';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { getKeyByValue } from 'lib/utils';

const StyledBanner = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.warningYellow};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderBrown};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  min-height: 70px;
  padding: 1rem;
`;

const StyledMaterialIcon = styled(MaterialIcon)`
  margin-left: auto;
  padding-right: 8px;
  vertical-align: text-bottom;
`;

const StyledMessage = styled.span`
  color: ${(props) => props.theme.colors.iconGrey};
  text-align: center;
  font-size: 0.85rem;
`;

const StyledCloseButton = styled.button`
  background-color: ${(props) => props.theme.colors.warningYellow};
  border: none;
  cursor: pointer;
  margin-left: auto;
  outline: none;
  padding-top: 5px;
`;

const StyledHyperlink = styled.button`
  color: ${(props) => props.theme.colors.hyperlinkBlue};
  cursor: pointer;
  background: none;
  border: none;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 24px;

  &:hover {
    color: ${(props) => props.theme.colors.hyperlinkBlueHover};
    text-decoration: underline;
  }
`;

const initialState = localStorage.onboardSpendDocs ? JSON.parse(localStorage.onboardSpendDocs) : [];

const FormattedTranslation = ({ merchantName, loanId, onClick }) => {
  const { t } = useTranslation();
  return (
    <StyledMessage>
      <Trans i18nKey="onboard_spend_banner.loan_documents_available" merchantName={merchantName}>
        Loan documents for {{ merchantName }} are available
      </Trans>
      &nbsp;
      <StyledHyperlink onClick={() => onClick(loanId)}>{t('onboard_spend_banner.here')}</StyledHyperlink>
    </StyledMessage>
  );
};

const OnboardSpendDocumentBanner = memo(() => {
  const loans = useSelector(loansSelector);
  const history = useHistory();

  const [items, setItems] = useState([]);
  const [viewedLoans, setViewedLoans] = useState(initialState);
  const [filteredLoans, setFilteredLoans] = useState([]);

  const addItem = useCallback(
    (item) => {
      // UPL-3638: Cutoff date is when these banners will be deployed to production
      // This is being released on on 2024-10-28
      const cutoffDate = moment('2024-10-28').format('YYYY-MM-DD');
      const isValidUpcode = Object.values(ONBOARD_SPEND_UPCODES).includes(item.upcode);
      const isNotViewed = !viewedLoans?.includes(item.loanId);
      if (moment(item.loanDate).isAfter(cutoffDate) && isValidUpcode && isNotViewed) {
        setItems((prevItems) => {
          if (!prevItems.some((i) => i.loanId === item.loanId)) {
            return [...prevItems, { loanId: item.loanId, upcode: item.upcode }];
          }
          return prevItems;
        });
      }
    },
    [viewedLoans]
  );

  useEffect(() => {
    if (loans && loans?.length > 0) {
      loans.forEach((loan) => {
        if (viewedLoans?.length === 0 || !viewedLoans?.includes(loan.loanId)) {
          addItem(loan);
        }
      });
      setFilteredLoans(items?.filter((item) => !viewedLoans?.includes(item.loanId)));
    }
  }, [addItem, items, loans, viewedLoans]);

  const handleViewed = useCallback(
    (loanId) => {
      const updatedViewedLoanDocs = [...viewedLoans, loanId];
      setViewedLoans(updatedViewedLoanDocs);
      localStorage.setItem('onboardSpendDocs', JSON.stringify(updatedViewedLoanDocs));
    },
    [viewedLoans]
  );

  const onClick = (loanId) => {
    history.push(`/loans#${loanId}/docs`);
    handleViewed(loanId);
  };

  const onClose = useCallback(
    (loanId) => {
      handleViewed(loanId);
    },
    [handleViewed]
  );

  return filteredLoans?.length > 0
    ? filteredLoans.map((item) =>
        viewedLoans?.includes(item.loanId) ? null : (
          <StyledBanner key={item.loanId}>
            <StyledMaterialIcon name="warning" color={theme.colors.warningBrown} />
            <FormattedTranslation
              merchantName={getKeyByValue(ONBOARD_SPEND_UPCODES, item.upcode)}
              loanId={item.loanId}
              onClick={onClick}
            />
            <StyledCloseButton type="button" onClick={() => onClose(item.loanId)}>
              <MaterialIcon name="close" size="24px" />
            </StyledCloseButton>
          </StyledBanner>
        )
      )
    : null;
});

OnboardSpendDocumentBanner.displayName = 'OnboardSpendDocumentBanner';

FormattedTranslation.propTypes = {
  merchantName: PropTypes.string.isRequired,
  loanId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default OnboardSpendDocumentBanner;
