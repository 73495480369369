/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useEffect, Suspense, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Router, Route, Redirect, Switch } from 'react-router-dom';

import GlobalDataProvider from 'components/GlobalDataProvider';

import { initializeUser } from 'state/auth/actions';

import { userSelector, isAdminUserSelector } from 'state/auth/selectors';
import { accountSelector, getMigrationData, getMigrationStatus } from 'state/account/selectors';

import Account from 'views/account';
import AdminAuthenticator from 'views/adminAuthenticator';
import Applications from 'views/applications';
import Authenticator from 'views/authenticator';
import Documents from 'views/documents';
import Statement from 'views/statement';
import Help from 'views/help';
import Loans from 'views/loans';
import Maintenance from 'views/maintenance';
import MigrationMaintenance from 'views/migrationMaintenance';
import Migrated from 'views/account/migrated/index';
import PayoffLetter from 'views/payoffLetter/index';
// import Boost from 'views/boost';

import Loader from 'components/Loader';

import useTracker from 'analytics/useTracker';
import { LoaderContainer } from 'styles/CommonStyles';
import { MIGRATION_STATUS } from 'lib/constants/migrationStatuses';
import { configure, signOutUrl } from './AwsAmplify';
import history from './History';

const FullScreenLoader = memo(() => {
  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  );
});

const PrivateComponent = memo(({ component: Component, location }) => {
  configure();

  const user = useSelector(userSelector);
  const account = useSelector(accountSelector);
  const migrationStatus = useSelector(getMigrationStatus);
  const migrationData = useSelector(getMigrationData);
  const hasUser = !!user;
  const authenticatedUser = !!(hasUser && user.signInUserSession);
  const dispatch = useDispatch();
  useTracker(location);

  // prettier-ignore
  useEffect(() => { // NOSONAR
    if (!hasUser) {
      dispatch(initializeUser());
    }
  }, [account, authenticatedUser, dispatch, hasUser]);

  if (authenticatedUser) {
    if (migrationStatus === MIGRATION_STATUS.MIGRATING) {
      return <MigrationMaintenance />;
    }

    if (migrationStatus === MIGRATION_STATUS.MIGRATED) {
      return <Migrated migrationData={migrationData} />;
    }

    return (
      <>
        <GlobalDataProvider>
          <Suspense fallback={<FullScreenLoader />}>
            <Component />
          </Suspense>
        </GlobalDataProvider>
      </>
    );
  }
  if (hasUser) {
    return (
      <Redirect
        to={{
          pathname: signOutUrl(),
        }}
      />
    );
  }
  return <FullScreenLoader />;
});

const PrivateRoute = ({ component, ...rest }) => {
  const renderPrivateRoute = useCallback((props) => <PrivateComponent component={component} {...props} />, [component]);
  // console.log('PrivateRoute: authenticatedUser: ', authenticatedUser)

  return <Route {...rest} render={renderPrivateRoute} />;
};

const PublicComponent = memo(({ component: Component, location }) => {
  const user = useSelector(userSelector);
  const hasUser = !!user;
  const authenticatedUser = !!(hasUser && user.signInUserSession);
  const adminUser = useSelector(isAdminUserSelector);

  const dispatch = useDispatch();
  useTracker(location);

  // prettier-ignore
  useEffect(() => { // NOSONAR
    if (!hasUser) {
      dispatch(initializeUser());
    }
  }, [dispatch, hasUser]);

  if (!authenticatedUser || adminUser) {
    return (
      <Suspense fallback={<FullScreenLoader />}>
        <Component />
      </Suspense>
    );
  }

  if (hasUser) {
    return (
      <Redirect
        push={false}
        to={{
          pathname: '/loans',
        }}
      />
    );
  }
  return <FullScreenLoader />;
});

const PublicRoute = ({ component, ...rest }) => {
  const renderPublicRoute = useCallback((props) => <PublicComponent component={component} {...props} />, [component]);
  return <Route {...rest} render={renderPublicRoute} />;
};
const DefaultRedirect = () => {
  return <Redirect to={{ pathname: '/loans' }} />;
};

const documentPaths = [
  '/account/document/:documentType',
  '/applications/:applicationId/document/:documentType',
  '/loans/:loanId/document/:documentType',
];

export default () => {
  if (process.env.REACT_APP_BP_MAINTENANCE_FLAG === 'true') {
    return (
      <Router history={history}>
        <Suspense fallback={<Loader />}>
          <Switch>
            <PublicRoute component={Maintenance} />
          </Switch>
        </Suspense>
      </Router>
    );
  }
  const migrationStatus = useSelector(getMigrationStatus);

  if (migrationStatus) {
    return (
      <Router history={history}>
        <Suspense fallback={<Loader />}>
          <Switch>
            <PublicRoute path="/signin" component={Authenticator} />
            <PublicRoute path="/ops" component={AdminAuthenticator} />

            {migrationStatus === MIGRATION_STATUS.MIGRATING && <PrivateRoute component={MigrationMaintenance} />}
            {migrationStatus === MIGRATION_STATUS.MIGRATED && <PrivateRoute component={Migrated} />}
          </Switch>
        </Suspense>
      </Router>
    );
  }

  return (
    <Router history={history}>
      <Suspense fallback={<Loader />}>
        <Switch>
          <PublicRoute path="/signin" component={Authenticator} />
          <PublicRoute path="/ops" component={AdminAuthenticator} />

          <PrivateRoute exact path="/account" component={Account} />
          <PrivateRoute exact path="/account/plaid" component={Account} />
          <PrivateRoute exact path="/applications" component={Applications} />
          <PrivateRoute exact path="/loans" component={Loans} />
          <PrivateRoute exact path="/loans/:loanId/statement" component={Statement} />
          <PrivateRoute exact path="/loans/:loanId/payoff" component={PayoffLetter} />
          <PrivateRoute exact path="/help" component={Help} />
          <PrivateRoute exact path={documentPaths} component={Documents} />

          {/* Default Component that loads */}
          <PrivateRoute component={DefaultRedirect} />
        </Switch>
      </Suspense>
    </Router>
  );
};
