import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import BootstrapAlert from 'react-bootstrap/Alert';
import BootstrapBadge from 'react-bootstrap/Badge';
import BootstrapAccordion from 'react-bootstrap/Accordion';
import BootstrapButton from 'react-bootstrap/Button';
import BootstrapCol from 'react-bootstrap/Col';
import BootstrapForm from 'react-bootstrap/Form';
import BootstrapProgressBar from 'react-bootstrap/ProgressBar';
import BootstrapRow from 'react-bootstrap/Row';
import BootstrapTable from 'react-bootstrap/Table';
import BootstrapCard from 'react-bootstrap/Card';
import BootstrapContainer from 'react-bootstrap/Container';
import 'lib/assets/css/styles-fonts.css';

export const Alert = styled(BootstrapAlert)`
  color: ${(props) => props.theme.colors.upliftRealLightBlack};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '0.9rem')};
  margin-top: ${(props) => (props.margintop ? props.margintop : '')};

  &.alert-info {
    background-color: ${(props) => props.theme.colors.infoBackground};
    border-color: ${(props) => props.theme.colors.infoAccent};
  }
`;

export const AlertMessage = styled.div`
  display: flex;
  flex-flow: row;
  gap: 8px;
  font-size: 0.9rem;
  font-weight: 500;
`;

export const Accordion = styled(BootstrapAccordion)``;
export const Card = styled(BootstrapCard)``;
export const Container = styled(BootstrapContainer)``;
export const Col = styled(BootstrapCol)``;
export const Form = styled(BootstrapForm)``;
export const ProgressBar = styled(BootstrapProgressBar)``;
export const Row = styled(BootstrapRow)``;
export const Table = styled(BootstrapTable)``;
export const FormCheck = styled(BootstrapForm.Check)``;

export const Badge = styled(BootstrapBadge)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  font-size: 1rem;
  line-height: 1.25;

  &.badge-pill {
    padding-left: 8px;
    padding-right: 8px;
  }

  &.badge-primary {
    background-color: ${(props) => props.theme.colors.backgroundTeal};
    color: ${(props) => props.theme.colors.upliftTeal};
  }

  &.badge-info {
    background-color: ${(props) => props.theme.colors.infoBackground};
    color: ${(props) => props.theme.colors.infoAccent};
  }
`;

export const Body = styled.div`
  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    padding: 0.5rem 7.5rem;
  }
`;

export const BodyBordered = styled.div`
  border: 1px solid ${(props) => props.theme.colors.upliftTealBorder};
  border-radius: 5px;
  margin-top: 2rem;
`;

export const buttonStyle = css`
  background-color: ${(props) => props.theme.colors.upgradeGreen};

  border: 1px solid ${(props) => props.theme.colors.upgradeGreen};
  border-radius: 8px;
  font-size: 0.85rem;
  font-weight: 600;
  box-shadow: none !important;

  &.btn-primary:not(:disabled):not(.disabled).active,
  &.btn-primary:not(:disabled):not(.disabled):active,
  &.show > .btn-primary.dropdown-toggle {
    color: ${(props) => props.theme.colors.upgradeActiveButton};
    background-color: ${(props) => props.theme.colors.upgradeGreen};
    border-color: ${(props) => props.theme.colors.upgradeActiveButton};
  }

  &.btn-primary:hover,
  &:hover {
    background-color: ${(props) => props.theme.colors.upgradeGreen};
    border: 1px solid ${(props) => props.theme.colors.upgradeActiveButton};
    &:not(:disabled):not(.disabled):active {
      border: 1px solid ${(props) => props.theme.colors.upgradeActiveButton};
    }
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.backgroundGrey};
    border-color: ${(props) => props.theme.colors.backgroundGrey};
    color: ${(props) => props.theme.colors.headerGrey};
    cursor: not-allowed !important;
  }
`;

export const Button = styled(BootstrapButton)`
  ${buttonStyle}
`;

const buttonOutlineStyle = css`
  background-color: #fff;
  color: ${(props) => props.theme.colors.upgradeGreen};

  border: 1px solid ${(props) => props.theme.colors.upgradeGreen};
  border-radius: 8px;
  font-size: 0.85rem;
  font-weight: 600;
  box-shadow: none !important;

  &.btn-primary:not(:disabled):not(.disabled).active,
  &.btn-primary:not(:disabled):not(.disabled):active,
  &.show > .btn-primary.dropdown-toggle {
    background-color: ${(props) => props.theme.colors.upgradeGreen};
    border: 1px solid ${(props) => props.theme.colors.upgradeGreen};
    color: ${(props) => props.theme.colors.upliftWhite};
  }
  &.btn-primary:hover,
  &:hover {
    background-color: ${(props) => props.theme.colors.upgradeGreen};
    border: 1px solid ${(props) => props.theme.colors.upgradeGreen};
    color: ${(props) => props.theme.colors.upliftWhite};
  }
`;

export const ButtonOutline = styled(BootstrapButton)`
  ${buttonOutlineStyle}

  &:disabled {
    background-color: transparent;
    border-color: ${(props) => props.theme.colors.headerGrey};
    color: ${(props) => props.theme.colors.headerGrey};
    cursor: not-allowed !important;

    &:hover {
      background-color: transparent;
    }
  }
`;

export const disabledHyperLinkStyles = css`
  color: ${(props) => props.theme.colors.upliftLightGrey};
  pointer-events: none;
`;

export const ButtonOutlineNavLink = styled(NavLink)`
  ${buttonOutlineStyle}
`;

export const ButtonHyperLink = styled.span`
  color: ${(props) => props.theme.colors.hyperlinkBlue};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.hyperlinkBlueHover};
    text-decoration: underline;
  }

  ${(props) => props.disabled && disabledHyperLinkStyles}
`;

export const ButtonLink = styled.div`
  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.hyperlinkBlue};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.hyperlinkBlueActive};
  }
`;

export const ButtonQuiet = styled.button`
  background: none;
  border: none;

  border-radius: 8px;
  color: ${(props) => props.theme.colors.upliftRealLightBlack};
  font-size: 1rem;
  line-height: 1.5;
  padding: 12px 24px;

  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundGrey};
    cursor: pointer;
  }

  &:active {
    background-color: ${(props) => props.theme.colors.backgroundGrey2};
  }
`;

export const ButtonPlain = styled.button`
  background-color: ${(props) => props.theme.colors.backgroundBlue};
  color: ${(props) => props.theme.colors.upliftWhite};

  border: none;
  outline: none;
  -webkit-appearance: none;

  padding: 0px 8px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }

  &:active:hover {
    text-decoration: none;
  }
`;

export const CC = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const Chip = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.background || props.theme.colors.secondaryMediumBlue};
  border-radius: 16px;
  color: ${(props) => props.color || props.theme.colors.white};

  font-size: 0.85rem;
  font-weight: 600;
  padding: 5px 1rem;
  margin: 5px 0px;

  text-align: center;
`;

export const Data = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
`;

export const Details = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  color: #555555;
  font-size: 0.9rem;
  font-weight: 400;

  padding: 1rem 0;
`;

export const Detail = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  padding: 0.5rem 0;
`;

export const ErrorMessage = styled.div`
  color: #dc3545;
  font-size: 0.9rem;
  margin: 5px 0px;
`;

export const ExpandButton = styled.button`
  background: white;
  color: ${(props) => props.theme.colors.hyperlinkBlue};
  border: none;
  outline: none;

  font-size: 0.9rem;

  &:disabled {
    color: ${(props) => props.theme.colors.indeterminate};
    cursor: not-allowed !important;
    box-shadow: none;
  }
`;

export const HiddenSpan = styled.span`
  display: none;

  @media screen and (min-width: ${(props) => props.theme.minWidth.nano}) {
    display: block;
  }
`;

export const IconDisc = styled.div`
  background-color: ${(props) => props.theme.colors.upliftLightestTeal};
  color: ${(props) => props.theme.colors.upliftTeal};

  display: table-cell;
  vertical-align: middle;
  text-align: center;

  width: 2.5rem;
  height: 2.5rem;

  min-width: 2.5rem;
  min-height: 2.5rem;

  border-radius: 50%;
  font-size: 1.2rem;

  margin-right: 0.5rem;
  padding-top: 8px;
`;

export const IconColor = '#2ea59a'; // upliftTeal
export const IconSize = '1.5rem';
export const IconColorHyperlinkBlue = '#156CED';

export const Label = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  align-items: center;

  width: 100%;
`;

export const Label2 = styled.label`
  font-size: 1rem;
  font-weight: 700;

  opacity: 0.8;
  color: #636c8c;

  margin-bottom: 5px;
`;

export const LabelledSeparator = styled.p`
  color: ${(props) => props.theme.colors.upliftRealLightBlack};
  margin: 0;
  overflow: hidden;
  text-align: center;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.colors.borderGrey};
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &:before {
    right: 0.5em;
    margin-left: -50%;
  }

  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
`;

export const IconWrapper = styled(Button)`
  display: flex;
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;

  padding: 0;
  line-height: inherit;

  &:hover,
  :focus,
  :active {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
`;

export const MaskedCC = styled.div`
  font-size: 1rem;
  font-weight: 400;
`;

export const MutedText = styled.div`
  color: #777777;

  font-size: 1rem;
  font-weight: 400;
`;

export const Panel = styled.div`
  background-color: ${(props) => props.theme.colors.upliftWhite};
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.1);

  margin: 0 8px 20px;

  padding: 2.3rem 0.5rem;

  @media screen and (min-width: ${(props) => props.theme.minWidth.femto}) {
    /* width: 383px; */
    padding: 2.3rem;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    width: 448px;
    margin: 0 auto 20px;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidth.nano}) {
    width: 604px;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    width: 732px;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidth.micro && props.isMigrated}) {
    width: 736px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const PanelHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  margin: 2rem 0;
`;

export const StyledDocument = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  margin: 0.5rem 0;
  font-size: 0.9rem;
`;

export const StyledInput = styled.input`
  display: block;
  width: 100%;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid ${(props) => (props.error ? '#dc3545' : '#ced4da')};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: ${(props) => (props.error ? '#dc3545' : '#80bdff')};
    outline: 0;
    box-shadow: 0 0 0 0.2rem ${(props) => (props.error ? 'rgba(220, 53, 69, 0.25)' : 'rgba(0, 123, 255, 0.25)')};
  }
`;

export const StyledSelect = styled.select`
  display: block;
  width: 100%;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid ${(props) => (props.error ? '#dc3545' : '#ced4da')};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem ${(props) => (props.error ? 'rgba(220, 53, 69, 0.25)' : 'rgba(0, 123, 255, 0.25)')};
  }
`;

// navbar only links
export const StyledLink = styled(NavLink)`
  color: ${(props) => props.theme.colors.black};
  font-size: 0.8em;
  font-weight: 500;

  padding: 22px 18px;

  &:hover {
    background-color: ${(props) => (props.color ? props.color : props.theme.colors.upliftLightestTeal)};
    border-bottom: 3px solid ${(props) => props.theme.colors.upliftTeal};
    color: rgba(0, 0, 0, 0.9) !important;
    text-decoration: none;
  }
`;

export const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.colors.upliftTeal};

  &:hover {
    color: ${(props) => props.theme.colors.upliftTealActive};
  }
`;

export const Switch = styled.label`
  position: relative;
  float: right;
  width: 50px;
  height: 24px;
`;
export const SwitchCheckbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;
export const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + &:before {
    left: unset;
    right: 4px;
  }

  input:checked + & {
    background-color: ${(props) => props.theme.colors.upliftTeal};
  }

  input:disabled + & {
    background-color: ${(props) => props.theme.colors.backgroundGrey};
    cursor: not-allowed;
  }
`;

export const MigratedStyledText = styled.span`
  color: ${(props) => props.theme.colors.grey};
  font-family: ${(props) => props.theme.font.fontFamily.flexpay};
  font-size: 16px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
`;

export const NextStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

export const NextStepsIndex = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  background: ${(props) => props.theme.colors.secondaryMediumBlue};
  color: white;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.gap ? props.gap : '8px')};
`;

export const StyledRow = styled.div`
  display: flex;
  gap: 8px;
`;

export const StyledListItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  align-items: baseline;
  width: 100%;
`;

export const StyledListText = styled.span`
  color: #383d47;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SpecialDescription = styled.div`
  border-radius: 8px;
  background: #f6efff;
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const StyledItalicizedText = styled.span`
  color: ${(props) => props.theme.colors.contentGreySecondary};
  font-feature-settings: 'liga' off;
  font-family: ${(props) => props.theme.font.fontFamily.flexpay};
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
`;

export const StyledOutboundLink = styled.a`
  color: ${(props) => props.theme.colors.upliftWhite} !important;
  display: flex;
  align-self: center;
  justify-content: center;
  width: 100%;

  /* Button styles from SignInButton */
  background-color: ${(props) => props.theme.colors.upgradeGreen};
  color: ${(props) => props.theme.colors.upliftWhite};

  border-style: none;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 600;

  padding: 8px 20px;

  text-transform: uppercase;

  cursor: pointer;
  outline: none;
  -webkit-appearance: none;

  &:active {
    background: ${(props) => props.theme.colors.upgradeGreen};
  }

  &:hover {
    background: ${(props) => props.theme.colors.upgradeGreen};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
`;
